/* LAPTOP */
.Careers {
  position: relative;
}

.Careers .PageCaption__content {
  grid-column-end: 12;
}

.Careers__section {
  padding-top: 72px;
}
.Careers__section__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Careers__section {
    padding-top: 40px;
  }
}

/* DESKTOP */

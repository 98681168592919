.BackToAbout {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f8f8f8;
}

.BackToAbout__title {
  grid-column-start: 2;
  grid-column-end: 12;

  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

.BackToAbout__desc {
  grid-column-start: 2;
  grid-column-end: 12;
  font-size: 20px;
  font-weight: 300;
}

.BackToAbout__action {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .BackToAbout__desc {
    font-size: 14px;
  }
}

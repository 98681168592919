/* LAPTOP */
.News {
  position: relative;
}

.News__list {
  padding-top: 72px;
  padding-bottom: 72px;
  grid-column-start: 2;
  grid-column-end: 12;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 24px;
}

.News__list__no-data {
  height: 500px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 13;
  padding-top: 64px;
}

.News__list-wrapper {
}

.News__list__item {
  padding: 24px;
  height: 320px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12.0456px 18.0684px rgba(139, 139, 139, 0.5);
  border-radius: 24px;
  margin-bottom: 32px;
}

.News__list__item__image {
  height: 132px;
  background: #f8f8f8;
  /* width: 40%; */
  flex-shrink: 0;
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.News__list__item__image img {
  height: auto;
  width: 100%;
}

.News__list__item__content {
  /* padding-left: 48px; */
  /* padding-right: 48px; */
}

.News__list__item__content__length {
  font-weight: 500;
  font-size: 12px;
  color: #a1a1a1;
}

.News__list__item__content__title {
  font-weight: 800;
  font-size: 16px;
  line-height: 140%;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--black);
  display: inline-block;
}

.News__list__item__content__meta {
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .News__list {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .News__list__item {
    grid-column-start: 1;
    grid-column-end: 4;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    height: auto;
  }

  .News__list__item__image {
    width: 100%;
    height: 120px;
    border-radius: 8px;
  }

  .News__list__item__content {
    padding: 0;
  }

  .News__list__item__content__length {
    margin-top: 16px;
  }

  .News__list__item__content__title {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .News__list {
    grid-column-start: 1;
    grid-column-end: 13;
    padding-left: 24px;
    padding-right: 24px;
  }
}

/* DESKTOP */

.Menu {
  height: 100%;
  width: 100%;
  padding: 8.33%;
  position: relative;
}

.Menu__dismiss {
  position: absolute;
  top: 4%;
  right: 4%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Menu__logo {
}

.Menu__logo img {
  height: 40px;
}

.Menu__links {
  margin-top: 24px;
}

.Menu__links__link {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--white);
  /* padding-top: 40px; */
  font-size: 12px;
  text-decoration: none;
  font-weight: 300;
}

.Menu__links__link-btn {
  height: 40px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f27224;
  color: white;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 120px;
  text-decoration: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
}

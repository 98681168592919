/* LAPTOP */
.Alumni {
  position: relative;
}

.Alumni__high-school {
  padding-top: 72px;
  padding-bottom: 0px;
}

.Alumni__high-school__content {
  grid-column-start: 2;
  grid-column-end: 6;
}

.Alumni__high-school__content__list {
  font-size: 20px;
  padding-left: 16px;
  font-weight: 300;
}

.Alumni__high-school__image {
  grid-column-start: 7;
  grid-column-end: 12;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Alumni__high-school__image img {
  width: 100%;
}

.Alumni__college {
  padding-top: 72px;
  padding-bottom: 72px;
}

.Alumni__college__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Alumni__college__content__lists {
  display: flex;
  flex-direction: row;
}

.Alumni__college__content__lists__list {
  width: 50%;
  font-size: 20px;
  font-weight: 300;
}

.Alumni__college__content__lists__list ul {
  padding-left: 16px;
}

.Alumni__reconnect {
  padding-top: 72px;
  padding-bottom: 72px;
}

.Alumni__reconnect__content {
  grid-column-start: 2;
  grid-column-end: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Alumni__reconnect__content__action {
  display: flex;
  flex-direction: row;
}

.Alumni__reconnect__content__action__btn {
  height: 64px;
  background: #f27224;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: white;
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 16px;
}

.Alumni__reconnect__image {
  grid-column-start: 8;
  grid-column-end: 13;
}
.Alumni__reconnect__image img {
  width: 100%;
}

.Alumni__tiles {
  display: flex;
  flex-direction: row;
  height: 400px;
}

.Alumni__tiles__item {
  height: 100%;
  width: 33.33%;
  padding: 80px;
  /* padding-bottom: 40px; */
  display: flex;
  flex-direction: column;
  color: white;
}

.Alumni__tiles__item__primary {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 8px;
}

.Alumni__tiles__item__secondary {
  font-weight: 300;
  font-size: 20px;
}

.Alumni__tiles__item__link {
  font-weight: 700;
  font-size: 20px;
  margin-top: auto;
  color: white;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Alumni__high-school {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .Alumni__high-school__image {
    display: none;
  }

  .Alumni__high-school__content {
    grid-column-end: 12;
  }

  .Alumni__high-school__content__list {
    font-size: 14px;
  }

  .Alumni__college {
    padding-top: 0px;
    padding-bottom: 40px;
  }

  .Alumni__college__content__lists__list {
    font-size: 14px;
  }

  .Alumni__reconnect {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .Alumni__reconnect__image {
    display: none;
  }

  .Alumni__reconnect__content {
    grid-column-end: 12;
  }

  .Alumni__reconnect__content__action__btn {
    height: 56px;
    font-size: 16px;
    width: 100%;
    margin-top: 8px;
  }

  .Alumni__tiles {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    height: 320px;
  }

  .Alumni__tiles__item {
    width: 80%;
    padding: 8.33%;
    flex-shrink: 0;
  }

  .Alumni__tiles__item__primary {
    font-size: 16px;
  }

  .Alumni__tiles__item__secondary {
    font-size: 16px;
  }

  .Alumni__tiles__item__link {
    font-size: 12px;
  }
}

/* DESKTOP */

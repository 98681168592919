.About {
  position: relative;
}

.About__section {
  padding-top: 56px;
  padding-bottom: 56px;
}

.About__section__content {
  grid-column-start: 2;
  grid-column-end: 6;
}

.About__section__content__cta {
  display: flex;
  flex-direction: row;
}

.About__section__image {
  grid-column-start: 7;
  grid-column-end: 12;
}

.About__section__image img {
  width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .About__section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .About__section__image {
    display: none;
  }

  .About__section__content {
    grid-column-start: 2;
    grid-column-end: 12;
  }
}

* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: "nunito";
  src: url("./fonts/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "nunito";
  src: url("./fonts/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "nunito";
  src: url("./fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "nunito";
  src: url("./fonts/Nunito-Light.ttf") format("truetype");
  font-weight: 300;
} */

@font-face {
  font-family: "lora";
  src: url("./fonts/Lora-Bold.ttf") format("truetype");
  font-weight: 700;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 14px;

  --black: #131313;
  --black-lighter: #403833;
  --white: #f5f5f5;
}

body {
  margin: 0;
  font-family: "nunito-sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding-left: 4%;
  padding-right: 4%;
}

.section {
  padding-top: 64px;
}

.section-content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.section-content-narrow {
  grid-column-start: 4;
  grid-column-end: 10;
}

.section-title {
  font-weight: 800;
  font-size: 40px;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  /* color: var(--black); */
}

.section-p {
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
  /* color: var(--black-lighter); */
}

.section-ul {
  font-weight: 300;
  font-size: 20px;
  padding-left: 16px;
  line-height: 150%;
}

.section-cta-wrapper {
  display: flex;
  flex-direction: row;
}

.section-cta {
  height: 64px;
  background: #f27224;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 16px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .app-grid {
    padding-left: 0;
    padding-right: 0;
  }

  .section {
    padding-top: 40px;
  }

  .section-title {
    font-size: 24px;
  }

  .section-p {
    font-size: 14px;
    line-height: 24px;
  }

  .section-ul {
    padding-left: 16px;
    font-size: 14px;
    line-height: 24px;
  }

  .section-cta {
    height: 56px;
    font-size: 16px;
    width: 100%;
  }

  .section-content-narrow {
    grid-column-start: 1;
    grid-column-end: 13;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-left: 8.33%; */
  /* padding-right: 8.33%; */
  padding: 4%;
  padding-top: 32px;
  padding-bottom: 32px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* z-index: 10; */
}

.Nav__brand {
}

.Nav__brand img {
  height: 32px;
}

.Nav__links {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Nav__links__link {
  margin-left: 32px;
  text-decoration: none;
  color: var(--white);
  /* font-weight: 700; */
  font-weight: 700;
}

.Nav__links__link.cta {
  height: 36px;
  padding-left: 32px;
  padding-right: 32px;
  background: #f27224;
  border-radius: 20px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.Nav-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  height: 56px;
  /* background: black; */
}

.Nav-mobile__brand {
}

.Nav-mobile__brand img {
  height: 20px;
}

.Nav-mobile__menu-btn {
  margin-left: auto;
}

/* MOBILE STYLES */
@media (min-device-width: 320px) and (max-device-width: 480px) {
}

.Footer {
  background: var(--black);
  color: var(--white);
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.Footer__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: solid 1px var(--white);
}

.Footer__title__logo {
}

.Footer__title__logo img {
  height: 40px;
}

.Footer__title__brand {
  margin-left: auto;
  text-align: right;
}

.Footer__title__brand__primary {
}

.Footer__title__brand__secondary {
}

.Footer__content {
  display: flex;
  flex-direction: row;
}

.Footer__content__info {
}

.Footer__content__info__contacts {
  padding-top: 40px;
}

.Footer__content__info__contacts__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.Footer__content__info__contacts__item__icon {
  width: 24px;
  margin-right: 16px;
}

.Footer__content__info__contacts__item__text {
}

.Footer__content__info__copyright {
  margin-top: 80px;
  font-size: 14px;

  /* padding-bottom: 40px; */
}

.Footer__content__info__copyright a {
  color: white;
}

.Footer__content__info__copyright__line {
  margin-bottom: 4px;
}

.Footer__content__socials {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-end;
  padding-top: 40px;
}

.Footer__content__socials__item {
  height: 32px;
  /* margin-left: 16px; */
  /* width: 40px; */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Footer {
    padding-top: 32px;
  }

  .Footer__title {
    padding-bottom: 24px;
  }
  .Footer__title__logo img {
    height: 20px;
  }

  .Footer__title__brand {
    font-size: 10px;
  }

  .Footer__content {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .Footer__content__info__contacts__item__text {
    font-size: 10px;
  }

  .Footer__content__info__contacts {
    padding-top: 32px;
  }

  .Footer__content__socials {
    margin-left: 0;
    justify-content: center;
  }

  .Footer__content__info__copyright {
    text-align: center;
    font-size: 10px;
  }
}

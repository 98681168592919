.ContactUs {
  position: relative;
}

.ContactUs__section {
  padding-top: 72px;
  padding-bottom: 72px;
}

.ContactUs__form {
  grid-column-start: 3;
  grid-column-end: 11;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 32px;
}

.ContactUs__form__section {
  margin-bottom: 24px;
}

.ContactUs__form__section__title {
  font-weight: 700;
}

.ContactUs__form__row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.ContactUs__form__row__group {
  margin-top: 16px;
}

.ContactUs__form__row__group__label {
  font-size: 12px;
  margin-bottom: 8px;
}

.ContactUs__form__row__group__input {
}

.ContactUs__form__row__group__input input {
  width: 100%;
  height: 36px;
  border-radius: 8px;
  border: solid 1px #e6e6e6;
  outline: none;
  padding-left: 8px;
}

.ContactUs__form__row__group__input textarea {
  border-radius: 8px;
  border: solid 1px #e6e6e6;
  width: 100%;
  height: 320px;
  outline: none;
  padding: 8px;
}

.ContactUs__form__submit {
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  background: #f27224;
  border-radius: 20px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.ContactUs__form__submit.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.ContactUs__form__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ContactUs__form__error {
  padding-left: 24px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .ContactUs__section {
    padding-top: 0px;
    padding-bottom: 48px;
  }

  .ContactUs__form {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .ContactUs__form__row {
    grid-template-columns: 100%;
  }

  .ContactUs__form__submit {
    width: 100%;
  }
}

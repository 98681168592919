/* LAPTOP */
.Trustees {
  position: relative;
}

.Trustees__list {
  padding-top: 72px;
}

.board-meeting-link {
  color: #f27224;
  font-weight: 600;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Trustees__list {
    padding-top: 40px;
  }
}

/* DESKTOP */

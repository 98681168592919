/* LAPTOP */
.Donate {
  position: relative;
}

.Donate__subheading {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding-top: 64px;
}

.Donate__image {
  width: 100%;
}

.Donate__form {
  padding-bottom: 64px;
}

/* .Donate__form form .lgld-field-display { */
/* margin-top: 24px; */
/* font-family: "nunito-sans", sans-serif !important; */
/* grid-column-start: 1;
  grid-column-end: 6; */
/* } */

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Donate__subheading {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    padding-top: 24px;
  }

  .Donate__image {
    width: 200%;
  }
}

/* DESKTOP */

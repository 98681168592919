.Layout {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.Layout__nav {
  background: var(--black);
  position: fixed;
  top: -110px;
  right: 0;
  width: 100%;
  height: 110px;
  z-index: 10;
  transition: all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s;
}

.Layout__menu {
  background: black;
  position: fixed;
  top: 0px;
  right: -100vw;
  width: 100%;
  height: 100%;
  z-index: 11;
  transition: all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Layout__nav {
    height: 56px;
  }
}

/* LAPTOP */
.Friends {
  position: relative;
}

.Friends__list {
  padding-top: 72px;
  padding-bottom: 72px;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
}

/* DESKTOP */

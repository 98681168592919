.PageCaption {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f8f8f8;
}

.PageCaption__content {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-direction: row;
}

.PageCaption__text {
  font-size: 20px;
  padding-right: 40px;
}

.PageCaption__text .bold {
  font-weight: 700;
}

.PageCaption__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.PageCaption__options__option {
  height: 56px;
  background: #f27224;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  padding-left: 56px;
  padding-right: 56px;
  margin-left: 56px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .PageCaption {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .PageCaption__text {
    font-size: 14px;
    padding-right: 0;
  }

  .PageCaption__content {
    flex-direction: column;
  }

  .PageCaption__options {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 24px;
  }

  .PageCaption__options__option {
    margin-left: 0;
    /* width: 50%; */
    height: 40px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

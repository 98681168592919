.Profile__item {
  grid-column-start: 2;
  grid-column-end: 12;
  background: #f8f8f8;
  border-radius: 20px;
  margin-bottom: 2rem;
  overflow: hidden;
}

.Profile__item__header {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding-left: 4%;
  padding-right: 4%;
}

.Profile__item__header__content {
  grid-column-start: 1;
  grid-column-end: 13;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Profile__item.expanded .Profile__item__header__content {
  border-bottom: solid 2px black;
}

.Profile__item__header__info {
}

.Profile__item__header__name {
  font-weight: 800;
  font-size: 16px;
  /* line-height: 150%; */
  /* identical to box height, or 39px */

  /* color: #000000; */
}

.Profile__item__header__email {
  /* font-family: "nunito"; */
  /* font-style: normal; */
  /* font-weight: 400; */
  font-size: 16px;
  /* line-height: 150%; */
  /* identical to box height, or 27px */

  /* color: #000000; */
}

.Profile__item__header__expand {
  margin-left: auto;
  cursor: pointer;
}

.Profile__item.expanded .Profile__item__header__expand {
  transform: rotateZ(180deg);
}

.Profile__item__body {
  position: relative;
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  padding-top: 3rem;
  padding-left: 4%;
  padding-right: 4%;
}

.Profile__item__body__img {
  /* position: absolute;
    left: 0;
    bottom: 0; */
  grid-column-start: 1;
  grid-column-end: 4;
  overflow: hidden;
  /* border-top-right-radius: 20px; */
}

.Profile__item__body__img img {
  width: 100%;
  height: auto;
}

.Profile__item__body__blurb {
  grid-column-start: 4;
  grid-column-end: 10;
  padding-left: 2rem;
  font-size: 16px;
  /* font-family: "nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%; */
  /* or 39px */

  /* color: #000000; */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Profile__item {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  .Profile__item__header__name {
    font-size: 1rem;
  }

  .Profile__item__header__content {
    align-items: flex-start;
    padding-top: 1rem;
    padding-bottom: 1rem;
    grid-column-start: 1;
    grid-column-end: 13;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .Profile__item__header__expand__svg {
    height: 2rem;
    width: 2rem;
  }

  .Profile__item__body {
    padding-top: 2rem;
  }

  .Profile__item__body__img {
    grid-column-start: 1;
    grid-column-end: 11;
  }

  .Profile__item__body__img img {
    width: 100%;
  }
  .Profile__item__body__blurb {
    grid-column-start: 2;
    grid-column-end: 10;
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .Profile__item__body__img {
    border-top-right-radius: 0px;
  }
}

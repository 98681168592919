/* LAPTOP */
.Admissions {
  position: relative;
}

.Admissions__pcs {
  padding-top: 72px;
  padding-bottom: 64px;
}

.Admissions__pcs__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Admissions__pcsnj {
  padding-top: 0px;
  padding-bottom: 72px;
}

.Admissions__pcsnj__content {
  grid-column-start: 2;
  grid-column-end: 7;
}

.Admissions__pcsnj__title {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Admissions__pcsnj__image {
  grid-column-start: 8;
  grid-column-end: 12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Admissions__pcsnj__image img {
  /* width: 100%; */
  /* height: auto; */
}

.Admissions__lottery {
  padding-top: 72px;
  padding-bottom: 72px;
}

.Admissions__lottery__title {
  grid-column-start: 2;
  grid-column-end: 12;
  text-align: center;
}

.Admissions__lottery__dates {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.Admissions__lottery__dates__date {
  font-size: 20px;
  border-right: solid 2px #f27224;
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 8px;
}

.Admissions__lottery__dates__date:first-child {
  text-align: right;
}

.Admissions__lottery__dates__date:last-child {
  border: none;
}

.Admissions__lottery__dates__date__label {
  font-weight: 800;
  color: #f27224;
  letter-spacing: 0.4px;
}

.Admissions__lottery__dates__date__value {
}

.Admissions__lottery__content {
  grid-column-start: 3;
  grid-column-end: 7;
  /* padding-right: 16%; */
}

.Admissions__lottery__content__action {
  display: flex;
  flex-direction: row;
}

.Admissions__lottery__content__action__btn {
  height: 64px;
  background: #f27224;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 48px;
  padding-right: 48px;
  /* width: inherit; */
  font-weight: 800;
  font-size: 20px;
  color: white;
  margin-top: 20px;
}

.Admissions__lottery__image {
  grid-column-start: 8;
  grid-column-end: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow: hidden;
  /* justify-content: flex-end; */
}

.Admissions__lottery__image img {
  /* width: 100%; */
}

.Admissions__lottery-preferences {
  display: flex;
  flex-direction: row;
}

.Admissions__lottery-preferences__section {
  width: 50%;
  /* padding: 4%; */
  /* padding-right: 0; */
  /* display: grid; */
  /* grid-template-columns: repeat(6, minmax(0, 1fr)); */
}

.Admissions__lottery-preferences__section__title {
  /* grid-column-start: 1; */
  /* grid-column-end: 7; */
  /* line-height: 48px; */
  margin-bottom: 8px;
  height: 56px;
}

.Admissions__lottery-preferences__section__text {
  /* grid-column-start: 1; */
  /* grid-column-end: 7; */
  /* padding-right: 4%; */
}

.Admissions__lottery-preferences__section__text .bold {
  /* font-weight: 700; */
}

.Admissions__lottery-preferences__section__list {
  font-weight: 300;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 24px;
  /* grid-column-start: 1; */
  /* grid-column-end: 7; */
}

.Admissions__weighted-lottery {
  padding-top: 72px;
  padding-bottom: 40px;
}

.Admissions__weighted-lottery__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Admissions__weighted-lottery__content__items {
  display: flex;
  flex-direction: row;
  margin-top: 64px;
}

.Admissions__weighted-lottery__content__items__item {
  width: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}

.Admissions__weighted-lottery__content__items__item__icon {
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f27224;
  color: white;
  font-weight: 700;
  font-size: 32px;
  flex-shrink: 0;
  border-radius: 100%;
}

.Admissions__weighted-lottery__content__items__item__text {
  font-size: 20px;
  padding-right: 40px;
  padding-left: 24px;
}

.Admissions__weighted-lottery__content__items__item__text__primary {
  font-weight: 700;
  margin-bottom: 8px;
}

.Admissions__weighted-lottery__content__items__item__text__secondary {
  font-weight: 300;
}

.Admissions__waitlists {
  padding-top: 64px;
  padding-bottom: 72px;
}

.Admissions__waitlists__content {
  grid-column-start: 2;
  grid-column-end: 7;
}

.Admissions__waitlists__content__action {
  display: flex;
  flex-direction: row;
}

.Admissions__waitlists__content__action__btn {
  height: 64px;
  background: #f27224;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: white;
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 24px;
}

.Admissions__waitlists__image {
  grid-column-start: 8;
  grid-column-end: 13;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
}

.Admissions__registration {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/admissions-4.png");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.Admissions__registration__btn {
  height: 64px;
  background: white;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  /* color: white; */
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 24px;
  color: black;
}

.Visit-hola {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #000000;
}

.Visit-hola__content {
  grid-column-start: 2;
  grid-column-end: 12;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.Visit-hola__section {
  /* width: 25%; */
  /* flex-shrink: 0; */
  border-right: solid 1px transparent;
}

.Visit-hola__section.cta {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Visit-hola__section__primary {
  font-weight: 800;
  color: #f27224;
  letter-spacing: 0.4px;
  color: white;
  font-size: 16px;
  margin-bottom: 2px;
}

.Visit-hola__section__secondary {
  color: white;
  font-size: 16px;
}

.Visit-hola__section__cta {
  height: 48px;
  max-width: 200px;
  background: white;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-weight: 700;
  font-size: 20px;
  padding-left: 48px;
  padding-right: 48px;
  /* margin-left: 56px; */
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Admissions__pcs {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .Admissions__pcsnj {
    padding-top: 40px;
    padding-bottom: 16px;
    position: relative;
  }

  .Admissions__pcsnj__image {
    position: absolute;
    right: -50%;
    display: none;
  }

  .Admissions__pcsnj__content {
    grid-column-end: 12;
  }

  .Admissions__lottery {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .Admissions__lottery__dates {
    grid-column-start: 1;
    grid-column-end: 13;
    margin-bottom: 32px;
  }

  .Admissions__lottery__dates__date {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .Admissions__lottery__image {
    display: none;
  }

  .Admissions__lottery__content {
    grid-column-end: 12;
  }

  .Admissions__lottery__content__action__btn {
    margin-top: 8px;
    width: 100%;
  }

  .Admissions__lottery-preferences {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .Admissions__lottery-preferences__section {
    width: 80%;
    flex-shrink: 0;
    padding-left: 24px !important;
    padding-top: 24px !important;
    display: block !important;
  }

  .Admissions__lottery-preferences__section__title {
    grid-column: 1 / 7 !important;
    padding-left: 0 !important;
  }

  .Admissions__lottery-preferences__section__text {
    grid-column: 1 / 7 !important;
    margin-top: 24px;
    padding-left: 0 !important;
  }

  .Admissions__lottery-preferences__section__list {
    grid-column: 1 / 7 !important;
    font-size: 14px;
    padding-left: 16px;
  }

  .Admissions__lottery-preferences__section__list li {
    margin-bottom: 8px;
  }

  .Admissions__weighted-lottery {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .Admissions__weighted-lottery__content__items {
    flex-direction: column;
    margin-top: 32px;
  }

  .Admissions__weighted-lottery__content__items__item {
    width: 100%;
    margin-bottom: 32px;
  }

  .Admissions__weighted-lottery__content__items__item__icon {
    height: 40px;
    width: 40px;
    font-size: 14px;
  }

  .Admissions__weighted-lottery__content__items__item__text {
    font-size: 14px;
  }

  .Admissions__waitlists {
    padding-top: 0;
  }

  .Admissions__waitlists__image {
    display: none;
  }

  .Admissions__waitlists__content {
    grid-column: 2 / 12;
  }

  .Admissions__waitlists__content__action__btn {
    height: 40px;
    font-size: 14px;
    margin-top: 8px;
    width: 100%;
  }

  .Admissions__registration__btn {
    height: 56px;
    font-size: 16px;
  }

  .Visit-hola {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .Visit-hola__content {
    display: flex;
    flex-direction: column;
  }

  .Visit-hola__section {
    margin-bottom: 24px;
    text-align: center;
  }

  .Visit-hola__section.cta {
    margin-bottom: 0;
    justify-content: flex-start;
    margin-top: 8px;
  }

  .Visit-hola__section__cta {
    height: 40px;
    font-size: 16px;
    max-width: none;
    width: 100%;
  }
}

/* DESKTOP */

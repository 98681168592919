/* LAPTOP */
.Academics {
  position: relative;
}

.Academics__section {
  /* padding-bottom: 72px; */
  padding-top: 72px;
}

.Academics__section__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Academics__section__content__image {
  margin-top: 48px;
}

.Academics__section__content__image img {
  width: 100%;
}

.Academics__timeline__items__item__text {
  display: flex;
  flex-direction: row;
}

.Academics__timeline__items__item__text__imgs {
  display: flex;
  flex-direction: row;
}

.Academics__timeline__items__item__text img {
  /* width: 128px; */
  margin-right: 24px;
}

.policy-link {
  display: block;
  text-decoration: underline;
  color: black;
  font-size: 16px;
  height: 24px;
  /* line-height: 1.5em; */
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Academics__section {
    padding-top: 40px;
  }

  .Academics__section__content__image {
    margin-top: 32px;
    margin-bottom: 32px !important;
  }

  .policy-link {
    height: auto;
  }
}

/* DESKTOP */

/* LAPTOP */
.StudentLife {
  position: relative;
}

.StudentLife__section {
  /* padding-bottom: 72px; */
  padding-top: 72px;
}

.StudentLife__section__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.StudentLife__sports {
  background: #f8f8f8;
  padding-top: 48px;
  padding-bottom: 48px;
  margin-top: 64px;
}

.StudentLife__sports__list {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-direction: row;
}

.StudentLife__sports__list__item {
  width: 25%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StudentLife__sports__list__item__image {
  margin-bottom: 20px;
  height: 120px;
}

.StudentLife__sports__list__item__primary {
  font-weight: 700;
  font-size: 20px;
}

.StudentLife__sports__list__item__secondary {
  font-weight: 300;
  font-size: 20px;
}

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .StudentLife__section {
    padding-top: 40px;
  }

  .StudentLife__sports {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .StudentLife__sports__list {
    flex-wrap: wrap;
  }

  .StudentLife__sports__list__item {
    width: 50%;
    margin-bottom: 40px;
  }

  .StudentLife__sports__list__item__primary {
    text-align: center;
  }

  .StudentLife__sports__list__item__image {
    height: 80px;
  }

  .StudentLife__sports__list__item__primary {
    font-size: 20px;
  }

  .StudentLife__sports__list__item__secondary {
    font-size: 14px;
  }
}

/* DESKTOP */

.ApplicationForm {
  padding-top: 72px;
  padding-bottom: 72px;
}

.ApplicationForm__body {
  grid-column-start: 2;
  grid-column-end: 12;
}

.ApplicationForm__success {
  grid-column-start: 3;
  grid-column-end: 11;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  border-radius: 8px;
  padding-top: 64px;
  padding-bottom: 64px;
}

.ApplicationForm__success__primary {
  font-weight: 800;
  font-size: 32px;
}

.ApplicationForm__success__secondary {
  font-weight: 400;
  font-size: 16px;
}

.ApplicationForm__title {
  font-weight: 800;
  font-size: 20px;
}

.ApplicationForm__title-secondary {
  font-weight: 800;
  font-size: 20px;
}

.ApplicationForm__row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 24px;
}

.ApplicationForm__group {
  margin-top: 36px;
}

.ApplicationForm__group__label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
}

.ApplicationForm__group__input input {
  height: 48px;
  border: 1px solid #000000;
  border-radius: 4px;
  width: 100%;
  padding-left: 12px;
}

.ApplicationForm__group__input select {
  height: 56px;
  border: 1px solid #000000;
  border-radius: 4px;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.ApplicationForm__seperator {
  border-top: solid 2px #f8f8f8;
  margin-top: 72px;
  margin-bottom: 72px;
}

.ApplicationForm__radio {
  margin-top: 32px;
}

.ApplicationForm__radio__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
}

.ApplicationForm__radio__option__icon {
  height: 20px;
  width: 20px;
  border: solid 1px black;
  border-radius: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ApplicationForm__radio__option__icon__selected {
  height: 12px;
  width: 12px;
  background: #f27224;
  border-radius: 100%;
}

.ApplicationForm__radio__option__label {
  font-weight: 700;
  font-size: 20px;
}

.ApplicationForm__error {
  margin-bottom: 24px;
  color: red;
}

.ApplicationForm__footer {
  display: flex;
  flex-direction: row;
}

.ApplicationForm__submit-btn {
  width: 330px;
  height: 73px;

  background: #f27224;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  border: none;
}

.ApplicationForm__submit-btn.disabled {
  pointer-events: none;
  opacity: 0.4;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .ApplicationForm__title {
    font-size: 16px;
  }

  .ApplicationForm__group__label {
    font-size: 14px;
  }

  .ApplicationForm__group__input input {
    height: 40px;
  }

  .ApplicationForm__group {
    margin-top: 24px;
  }

  .ApplicationForm__seperator {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .ApplicationForm__row {
    grid-column-gap: 12px;
  }

  .ApplicationForm__title-secondary {
    font-size: 16px;
  }

  .ApplicationForm__radio__option {
    margin-bottom: 16px;
  }

  .ApplicationForm__radio__option__label {
    font-size: 14px;
  }

  .ApplicationForm__submit-btn {
    width: 100%;
  }
}

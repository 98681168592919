/* LAPTOP */
.Home {
  position: relative;
}

.Home__hero {
  background-image: url("../../images/home-hero.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  /* background-size: cover; */
  background-position: top;
  height: 100vh;
  /* min-width: 100%; */
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  /* padding-left: 12%; */
  color: var(--white);
}

.Home__hero__content {
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Home__hero__heading {
  font-family: "lora";
  /* font-family: lust-display, serif; */
  /* font-weight: 400; */
  font-style: normal;
  font-size: 40px;
  margin-bottom: 24px;
  margin-top: 48px;
}

.Home__hero__subheading {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.Home__hero__logo {
}

.Home__hero__logo img {
  height: 200px;
}

.Home__hero-footer {
  background: #f8f8f8;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 20px;
  line-height: 36px;
  padding-top: 32px;
  padding-bottom: 32px;
  /* display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding-left: 4%;
  padding-right: 4%; */
}

.Home__hero-footer__text {
  /* width: 83%; */
  grid-column-start: 2;
  grid-column-end: 12;
  /* color: var(--black-lighter); */
}

.Home__hero-footer__text .bold {
  font-weight: 700;
}

.Home__desc {
  padding-top: 64px;
  padding-bottom: 64px;
}

.Home__desc__content {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Home__desc__content__primary {
  font-weight: 800;
  font-size: 48px;
}

.Home__desc__content__secondary {
  font-size: 20px;
  line-height: 36px;
  margin-top: 40px;
  font-weight: 300;
  /* color: var(--black-lighter); */
}

.Home__desc__content__links {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.Home__desc__content__links__link {
  /* width: 25%; */
  /* flex-shrink: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home__desc__content__links__link img {
  height: 100px;
  margin-left: 40px;
  margin-right: 40px;
}

.Home__carousel {
  height: 400vh;
  position: relative;
  background: var(--black);
  /* overflow-y: auto; */
}

.Home__carousel__slide {
  height: 100vh;
}

.Home__carousel__slide__content {
  grid-column-start: 2;
  grid-column-end: 11;
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  height: 100%;
  font-weight: 800;
  font-size: 40px;
  line-height: 150%;
  /* or 58px */

  letter-spacing: 0.01em;
  color: var(--white);
  z-index: 1;
}

.Home__carousel__slide__content__p {
  margin-top: -10vh;
}

.Home__carousel__slide__content__cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 72px;
}

.Home__carousel__slide__content__cta__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home__carousel__slide__content__cta__btn {
  font-size: 24px;
  height: 64px;
  border-radius: 32px;
  padding-left: 40px;
  padding-right: 40px;
  border: solid 2px white;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.Home__carousel__bg {
  position: absolute;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow: hidden;
  /* left: 0; */
  /* top: 0; */
  pointer-events: none;
  z-index: 0;
}

.Home__carousel__bg__img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Home__carousel__bg__img img {
  height: auto;
  width: 100%;
}

.Home__carousel__bg__overlay {
}

.Home__carousel__pagination {
  display: flex;
  flex-direction: column;
  width: 24px;
  position: absolute;
  right: 50px;
  top: 50%;
}

.Home__carousel__pagination__page {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: #585858;
  margin-bottom: 20px;
}

.Home__carousel__pagination__page.selected {
  background: white;
}

.Home__stats {
}

.Home__stats__content {
  grid-column-start: 2;
  grid-column-end: 12;
  padding-bottom: 64px;
}

.Home__stats__content__title {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  margin-top: 80px;
  margin-bottom: 56px;
}

.Home__stats__content__graphs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 8%;
}

.Home__stats__content__graphs__graph {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home__stats__content__graphs__graph__donut {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home__stats__content__graphs__graph__donut__chart {
}

.Home__stats__content__graphs__graph__donut__legend {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home__stats__content__graphs__graph__donut__legend__primary {
  font-size: 48px;
  font-weight: 700;
  line-height: 80px;
}

.Home__stats__content__graphs__graph__donut__legend__secondary {
  font-weight: 300;
  font-size: 20px;
}

.Home__stats__content__graphs__graph__donut__label {
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
}

.Home__ratios {
  background: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 40px;
}

.Home__ratios__title {
  grid-column-start: 2;
  grid-column-end: 6;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Home__ratios__title__primary {
  font-weight: 800;
  font-size: 32px;
}

.Home__ratios__title__secondary {
  font-weight: 600;
  font-size: 20px;
}

.Home__ratios__list {
  grid-column-start: 6;
  grid-column-end: 12;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.Home__ratios__list__item {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.Home__ratios__list__item__value {
  font-weight: 800;
  font-size: 32px;
}

.Home__ratios__list__item__key {
  font-weight: 600;
  font-size: 20px;
}

.Home__timeline {
  padding-top: 80px;
  padding-bottom: 24px;
}

.Home__timeline__title {
  grid-column-start: 2;
  grid-column-end: 12;
  margin-bottom: 64px;
}

.Home__timeline__title__primary {
  font-weight: 800;
  font-size: 40px;
}

.Home__timeline__title__secondary {
  font-weight: 600;
  font-size: 20px;
}

.Home__timeline__items {
  grid-column-start: 2;
  grid-column-end: 12;
}

.Home__timeline__items__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
}

.Home__timeline__items__item__year {
  width: 120px;
  flex-shrink: 0;
  font-weight: 800;
  font-size: 20px;
  color: #a4a4a4;
}

.Home__timeline__items__item__progress {
  width: 80px;
  flex-shrink: 0;
  position: relative;
}

.Home__timeline__items__item__progress__bullet {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #a4a4a4;
  margin-top: 6px;
}

.Home__timeline__items__item__progress__bar {
  position: absolute;
  /* height: 100%; */
  width: 4px;
  background: black;
  left: 8px;
  top: 7px;
  z-index: -1;
  /* transition: all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s; */
}

.Home__timeline__items__item__text {
  flex-grow: 1;
  font-weight: 300;
  font-size: 20px;
  color: #a4a4a4;
}

.Home__timeline__items__item.black .Home__timeline__items__item__year {
  color: black;
}

.Home__timeline__items__item.black
  .Home__timeline__items__item__progress__bullet {
  background: black;
}

.Home__timeline__items__item.black .Home__timeline__items__item__text {
  color: black;
}

.Home__team {
  padding-bottom: 48px;
  padding-top: 48px;
}

.Home__team__content {
  grid-column-start: 2;
  grid-column-end: 7;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* padding-right: 40px; */
}

.Home__team__content__title {
  font-weight: 800;
  font-size: 40px;
  padding-right: 40px;
  margin-bottom: 24px;
  margin-top: 32px;
}

.Home__team__content__txt {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 40px;
  line-height: 150%;
}

.Home__team__content__action {
  display: flex;
  flex-direction: row;
}

.Home__team__content__action__btn {
  height: 64px;
  background: #f27224;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 48px;
  padding-right: 48px;
  /* width: inherit; */
  font-weight: 800;
  font-size: 20px;
  color: white;
}

.Home__team__graphic {
  grid-column-start: 8;
  grid-column-end: 12;
}

.Home__subscribe {
  background: #f8f8f8;
  padding-top: 48px;
  padding-bottom: 48px;
}

.Home__subscribe__title {
  grid-column-start: 3;
  grid-column-end: 11;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.Home__subscribe__input-wrapper {
  grid-column-start: 3;
  grid-column-end: 11;
  height: 68px;
  border-radius: 45px;
  background: white;
  border: solid 2px black;
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.Home__subscribe__input {
  flex-grow: 1;
  padding-left: 24px;
}

.Home__subscribe__input input {
  height: 100%;
  padding: 0;
  width: 100%;
  font-weight: 300;
  font-size: 20px;
  font-family: "nunito-sans", sans-serif;
  border: none;
  outline: none;
}

.Home__subscribe__input input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a7a7a7;
  opacity: 1; /* Firefox */
}

.Home__subscribe__input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a7a7a7;
}

.Home__subscribe__input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a7a7a7;
}

.Home__subscribe__btn {
  height: 100%;
  border-radius: 45px;
  background: #f27224;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: 800;
  font-size: 16px;
  border: none;
}

.Home__subscribe__btn.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.Home__subscribe__success {
  grid-column-start: 3;
  grid-column-end: 11;
  text-align: center;
  margin-top: 32px;
  color: green;
}

.Home__subscribe__action {
  grid-column-start: 3;
  grid-column-end: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .Home__carousel__content {
  height: 300vw;
} */

/* MOBILE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Home__hero {
    padding-right: 8.33%;
  }

  .Home__hero__content {
    width: 100%;
    overflow: hidden;
  }

  .Home__hero__heading {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 48px;
  }

  .Home__hero__logo {
  }

  .Home__hero__logo img {
    width: 100%;
    height: auto;
  }

  .Home__hero__subheading {
    font-size: 24px;
    margin-top: 16px;
  }

  .Home__hero-footer {
    font-size: 14px;
    line-height: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .Home__desc {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .Home__desc__content__primary {
    font-size: 24px;
  }

  .Home__desc__content__secondary {
    font-size: 14px;
    line-height: 24px;
    margin-top: 16px;
  }

  .Home__desc__content__links {
    margin-top: 32px;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .Home__desc__content__links__link {
    /* width: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .Home__desc__content__links__link img {
    height: 80px;
    width: auto;

    margin: 0;
  }

  .Home__carousel__slide__content {
    font-size: 24px;
  }

  .Home__carousel__bg__img img {
    /* height: 100%; */
    /* width: auto; */
  }

  .Home__carousel__pagination {
    opacity: 0;
  }

  .Home__carousel__slide__content__cta {
    margin-top: 32px;
    justify-content: flex-start;
  }

  .Home__carousel__slide__content__cta__icon svg {
    height: 40px;
  }

  .Home__carousel__slide__content__cta__btn {
    height: 40px;
    font-size: 16px;
    margin-left: 16px;
  }

  .Home__stats__content {
    padding-bottom: 40px;
  }

  .Home__stats__content__title {
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .Home__stats__content__graphs {
    display: block;
  }

  .Home__stats__content__graphs__graph {
    margin-bottom: 40px;
  }

  .Home__ratios {
    display: block;
    padding-left: 8.33%;
    padding-right: 8.33%;
  }

  .Home__ratios__list {
    display: block;
  }

  .Home__ratios__list__item {
    align-items: flex-start;
    margin-top: 40px;
  }

  .Home__ratios__title__primary {
    font-size: 32px;
  }

  .Home__ratios__title__secondary {
    font-size: 20px;
  }

  .Home__ratios__list__item__key {
    font-size: 20px;
  }

  .Home__timeline {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .Home__timeline__title {
    margin-bottom: 40px;
  }

  .Home__timeline__title__primary {
    font-size: 32px;
  }

  .Home__timeline__title__secondary {
    font-size: 20px;
  }

  .Home__timeline__items__item {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .Home__timeline__items__item__year {
    font-size: 16px;
  }

  .Home__timeline__items__item__text {
    font-size: 14px;
  }

  .Home__timeline__items__item__progress {
    display: none;
  }

  .Home__team {
    display: block;
    padding-left: 8.33%;
    padding-right: 8.33%;
    position: relative;
  }

  .Home__team__content__title {
    font-size: 32px;
  }

  .Home__team__content__txt {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .Home__team__content__action__btn {
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
  }

  .Home__team__graphic {
    position: absolute;
    top: -50px;
    right: -18%;
  }

  .Home__team__graphic img {
    height: 160px;
  }

  .Home__subscribe {
    padding-top: 40px;
    padding-bottom: 40px;
    display: block;
    padding-left: 8.33%;
    padding-right: 8.33%;
  }

  .Home__subscribe__title {
    font-size: 16px;
    /* text-align: left; */
  }

  .Home__subscribe__input-wrapper {
    height: 40px;
    border-radius: 20px;
    padding: 2px;
  }

  .Home__subscribe__input {
    padding-left: 8px;
  }

  .Home__subscribe__input input {
    font-size: 12px;
  }

  .Home__subscribe__btn {
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* DESKTOP */

.Hero {
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  /* background-image: url("../../../images/admissions-hero.png"); */
}

.Hero__text {
  font-weight: 900;
  font-size: 88px;
  line-height: 88px;
  color: var(--white);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Hero {
    height: 50vh;
  }

  .Hero__text {
    font-size: 40px;
    line-height: 60px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
  }
}
